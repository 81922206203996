import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-unificator/services';
import {distinctUntilChanged, first, map, tap} from 'rxjs/operators';
import {PlatformService} from './platform.service';
import {WindowService} from './window.service';
import {GlobalEventsService} from './global-events.service';
import {GroupsService} from './groups.service';
import {LanguageService} from './language/language.service';
import {FIRST_TIME_DEP, UserService} from './user/user.service';
import {CmsContentMapperService} from './cms-content-mapper.service';
import {CmsApiService} from './api/cms-api.service';
import {AB_TEST_LIST} from '../ab-test/ab-test.data';



declare global {
  interface Window {
    OneSignal: any;
    OneSignalDeferred: any;
  }
}

declare var OneSignal;

export const EXCLUDE_PUSH_PROMPT_PAGES = [
  '/404',
  '/403',
  'login',
  'registration',
  'deposit'
];

@Injectable({
  providedIn: 'root'
})
export class OneSignalService {

  constructor(
    private _platform: PlatformService,
    private _window: WindowService,
    private _globalEvents: GlobalEventsService,
    private _cookie: CookieService,
    private _groups: GroupsService,
    private _language: LanguageService,
    private _user: UserService,
    private _api: CmsApiService,
    private _mapper: CmsContentMapperService,
  ) {
  }

  /**
   * Get one signal id from CMS
   */
  handleId() {
    if (this._platform.isBrowser) {
      this._api.staticContentItem({slug: 'wf-one-signal'}).pipe(
        first(),
        map(response => this._mapper.mapCmsData(
          response.data && response.data.item, {name: 'name'})[0]),
        tap((data) => this._onInit(data?.name))
      ).subscribe();
    }
  }
  /**
   * Init one signal and apply id from CMS
   */
  private _onInit(id: string): void {
    window.OneSignalDeferred = window.OneSignalDeferred || [];
    window.OneSignalDeferred.push(async () => {
      await OneSignal.init({
        appId: id,
        notifyButton: {
          enable: true
        },
        serviceWorkerParam: { scope: '/push/onesignal/' },
        serviceWorkerPath: 'push/onesignal/OneSignalSDKWorker.js',
      }).then(() => {
        this._autoUpdateUser();
        this._setExternalId();
      });
    });
  }
  // This function will work only if Onesignal init. So it will don`t work for localhost or stage
  // And will not send test users data. To make it work change in Onesignal admin panel in app test url to localhost
  private _autoUpdateUser() {
    this._globalEvents.routerNavigationEnd$.pipe(
      distinctUntilChanged(),
      tap((data) => {
        OneSignal.push(() => {
          this._setUserTags(this._createUserTagsObj());
        });
      })
    ).subscribe();
  }

  private _setUserTags(data) {
    OneSignal?.User?.addTags(data);
  }

  private _createUserTagsObj() {
    const groups = Object.values(AB_TEST_LIST)?.map(v => v?.resolvedValue)?.filter(v => !!v) || []; //Get ab test groups
    const resultObject = {};
    groups?.forEach(key => {
      resultObject[key] = true;
    });

    if (this._user.auth) {
      return {
        isUserRegistered: this._cookie.check('registered')?.toString(),
        isUserHadDeposit: (this._groups.isExistGroup(FIRST_TIME_DEP) || this._user.accountList.some((e => e.amount > 0)))?.toString(),
        userLanguage: this._language.current,
        isHaveBalance: this._user.accountList.some((e => e.amount > 0))?.toString(),
        ...resultObject,
      };
    } else {
      return {
        isUserRegistered: this._cookie.check('registered')?.toString(),
        userLanguage: this._language.current,
        ...resultObject,
      };
    }
  }


  private _setExternalId() {
    OneSignal.push( async () => {
      if (OneSignal?.User?.PushSubscription?.id) {
        OneSignal.login(OneSignal.User.PushSubscription.id);
      }
    });
  }
}
